<template>
  <div class="grouplist-wrap">
    <div class="bread-wrap">
      <span class="bread-title">充值管理</span>
    </div>
    <div class="content">
      <com-table
        class="grouplist"
        :headercellstyle="headercellstyle"
        :columns="moneydetaillist"
        :pagination="pagination"
        tableheight="calc(100vh - 250px)"
        :data="tabledata"
      >
      </com-table>
    </div>
  </div>
</template>
<script>
import ComTable from "@/components/Table";
import { moneydetaillist } from "@/utils/tableList";
export default {
  name: "moneydetaillist",
  data() {
    return {
      moneydetaillist,
      pagination: {
        current: 1,
        size: 20,
        total: 1,
      },
      tabledata: [],
      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },
      row: {},
      title: "",
    };
  },
  components: {
    ComTable,
  },
  mounted() {
    this.getgrouplist();
    this.getuserinfo();
  },
  methods: {
    getgrouplist() {
      this.$store.dispatch("user/listrecharge").then((res) => {
        if (res.RetCode == 0) {
          this.tabledata = res.List;
        }
      });
    },
    getuserinfo() {
      this.$store.dispatch("user/getuserinfo").then((res) => {
        if (res.RetCode == 0) {
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>